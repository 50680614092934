import Image from 'next/image';
import NextLink from 'next/link';

import { Button, Grid, Stack, Typography } from '@mui/material';

const error404 = '/images/Error404.png';

export const NotFound = () => (
  <Grid
    container
    spacing={2}
    direction="column"
    alignItems="center"
    justifyContent="center"
    sx={{ minHeight: '50vh', pt: 1.5, pb: 1, overflow: 'hidden' }}
  >
    <Grid item xs={12}>
      <Stack direction="row">
        <Grid
          item
          sx={{
            position: 'relative',
            width: { xs: 250, sm: 320 },
            height: { xs: 250, sm: 320 },
          }}
        >
          <Image src={error404} alt="404 illustration" layout="fill" />
        </Grid>
      </Stack>
    </Grid>
    <Grid item>
      <Stack spacing={2} justifyContent="center" alignItems="center">
        <Typography variant="h1">404 | Page Not Found</Typography>
        <Typography
          color="textSecondary"
          align="center"
          sx={{ width: { xs: '73%', sm: '61%' } }}
        >
          The page you are looking was moved, removed, renamed, or might have
          never existed. Try to navigate back or start from home by clicking
          button below.
        </Typography>
        <NextLink href="/" passHref legacyBehavior>
          <Button size="small" variant="contained">
            Back To Home
          </Button>
        </NextLink>
      </Stack>
    </Grid>
  </Grid>
);
